@import "config";
@import "mixins";

.tabs {
	//.winShadow();
	//.baradius(5px);
	.mb(1rem);

}
.tabs-list {


	> li {
		.p(10px @winPadLR);
		.clr(#000);
		.fs(1.2rem);
		border-radius: 10px 10px 0 0;
		.bgr(#fff);
		box-shadow: 0 -1px 3px rgba(0,0,0,.2);
		//.winShadow();

		.pointer;
		//.z(1);

		&.active {
			.bgr(@greyBgr);
			.clr(#fff);
		}

		&:not(:first-child) {
			.ml(-5px);
			.pl(15px);
		}
		&:not(.active) {
			&:hover {
				.bgr(@borderClr);
			}
		}
	}
}
.tabs-panels {
	.relative;

	> * {
		.absolute;
		left: 0;
		top: 0;
		.dNone;
		.p(@winPadLR);
		.bgr(#fff);
		border-radius: 0 0 5px 5px;
		//.winShadow();
		box-shadow: 0 1px 3px rgba(0,0,0,.2);
		&.active {
			.dBlock;
			position: static;
		}
	}
}
