@import 'mixins.less';
@import 'config.less';

.orders-btn-create-order {
  @media @desktop {
	.mr(30px);
  }
}

.os-man-info {
  .bgr(@topBarSrchBgr);
  .baradius(5px);
  .p(@winPadLR);
}

@colN: 5%;
@colCreated: 10%;
@colS: 10%;
@colPrep: 15%;
@colD: @colPrep;
@colA: 15%;
@colM: 10%;
@colCur: 10%;
@colTel: 10%;

.orders-list {


  .col-n {
    .w(@colN);
    .tac;
  }
  .col-created {
    .w(@colCreated);
    .tac;
  }
  .col-status {
    .w(@colS);
    .tac;
  }
  .col-client {
    .w(@colS)
  }
  .col-prepare {
    .w(@colPrep);
  }
  .col-delivery {
    .w(@colD);

  }
  .col-address {
    .w(@colA);

    p {
      .p(0);
      .m(0 0 5px 0);
    }
  }
  .col-manager {
    .w(@colM)
  }
  .col-cur {
    .w(@colCur)
  }
  .col-tel {
    .w(@colTel)
  }

  .tbody {
    .fs(0.8rem);

    .col-n {

      @media @desktop {
        .p(0);

        > * {
          .box(100%);
          .p(10px);
        }
        > a {
          .tdUnd;
          .clr(@blueClr);
          .dBlock;
          //.bgr(blue);

          &:hover {
            .tdUnd;
          }
        }
      }
      @media @mobile {
        .fs(1rem);
      }

    }
    .col-prepare,
    .col-delivery {
      .tac;
    }



  }


  .col-last {
    flex: 1;
  }
  .tr {
    .td {
      &:last-child {
        flex: 1;
      }
    }
  }
  //для курьера
  &.ol-4-courier {
    .col-n {
      order: 10;
    }
    .col-created {
      order: 20;
    }
    .col-status {
      order: 30;
    }
    .col-prepare {
      order: 40;
    }
    .col-delivery {
      order: 50;
    }
    .col-manager {
      order: 60;
    }
    .col-client {
      order: 65;
    }

    .col-tel {
      order: 80;
    }
    .col-address {
      order: 70;
      .br(@borderColor);
    }
    @media @mobile {
      .col-n,
      .col-created,
      .col-status
      {
        .w(33%);
      }
    }
  }

	.col-in-comment {
		.p(5px);
		.mt(10px);
		.bgr(#fff);
		.baradius(5px);
		.clr(@txtClr);
	}
}
//.order-item {
//
//}
//города в настройках
.orders-sets-city {
  .dFlexi;
  align-items: center;
  .m(0 10px 10px 0);
  .p(10px 20px);
  .bgr(#ccc);
  .baradius(@borderRadius);

  .-n {
    flex: 1;
    .pr(10px);
  }
  i {
    &:hover {
      .clr(#fff);
      .pointer;
    }
  }
}
.order-phone-wrp {
  .relative;
}
.order-phone-address-list {
  .absolute;
  left: 0;
  top: 99%;
  .z(20);
  .bgr(#fff);
  .w100;
  .winShadow();
  .border(@inputBorderClrHover);
  > * {
    .p(10px 20px);
    &:not(:last-child) {
      .bb(@borderColor);
    }
    &:hover {
      .bgr(@blueClr);
      .clr(#fff);
      .pointer;
    }
  }
}
.opal-title {
  .fs(14px);
}
