@import 'mixins.less';
@import 'config.less';

@media @desktop {
  .top-bar {
	&.full {
	  .pl(@menuSideWidthClose + @menuPaddingLR);
	}

	.menu-bars {
	  .clr(@txtClr);
	  .mr(20px);
	  .fs(1.2rem);
	}
  }
  .tb-srch {
	.w(30%);
  }
  main {
	padding: @topBarHeight 0 0 @menuSideWidth;
	&.full {
	  .pl(@menuSideWidthClose);
	}
  }
  .ms-top {
	.menu-bars,
	.tb-user
	{
	  .dNone;
	}

  }
  .menu {
	.pt(5vh);
  }
  .menu-side {
	.menu > ul {
	  > li {
		> a {
		  .flexCntr;

		  > span {
			.dNone;
		  }


		}
	  }
	}
	.menu-sub {
		> li {
		  > a {
			i {
			  .mr(@menuPaddingLR);
			}
			> span {
			  //.dBlock;

			}
		  }
		}
	}
	&.open {
	  .w(@menuSideWidth);

	  .menu ul {
		> li {
		  > a {
			.pl(15px);
			.pr(15px);
			justify-content: flex-start;
			i {
			  .mr(@menuPaddingLR);
			}
			> span {
			  .dBlock;
			}
		  }
		}
	  }
	}
	//&:not(.open):hover  {
	//
	//  .menu {
	//	ul {
	//	  > li {
	//		> a {
	//		  &:hover {
	//			.relative;
	//
	//			> span {
	//			  //.dNone;
	//			  .dBlock;
	//			  .absolute;
	//			  left: -20px;
	//			  top: -25px;
	//			  .w(@menuSideWidthClose);
	//			  .z(20);
	//
	//			  .bgr(#000);
	//			  .fs(.6rem);
	//			  .p(5px);
	//			  .baradius(2px);
	//			  //.noWrap;
	//			  .tac;
	//			}
	//		  }
	//		}
	//	  }
	//	}
	//  }
	//}
  }

  .tbl {
	.td-title {
	  .dNone;
	}
  }



}
@media @mobile {
  .tbl {
	.thead {
	  .dNone;
	}
	.td-title {
	  .dBlock;
	  .m(-10px -10px 20px -10px);
	  .p(5px);
	  .bgr(#f1f1f1);
	  .fs(1rem);
	  .clr(@txtClr) !important;
	  .bold;
	  br {
		.dNone;
	  }
	}
	.tbody {
	  .tr {
		.bt(@txtClr, 5px);
		&:not(:last-child) {
		  //.bb(@borderColor, 5px);
		  //.mb(20px);
		}
	  }
	}
	.td {
	  .w(50%);
	}
	.sub-hdr {
	  .w100;
	}
  }
  .row {
	.col-2,
	.col-3,
	.col-2-3 {
	  .w100;
	}
  }
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-2-3 {
	.w100;
	.m(5px 0);
  }

  .ms-top {
	.w100;
	&.open {
	  .w100;
	}

	.menu-bars {
	  order: 1;
	}
	.logo {
	  order: 2;
	  .dNone;
	}
	.tb-user {
	  order: 3;
	  .clr(#fff);
	  &:before {
		.dNone;
	  }
	}

  }
  .menu-side {
	.w(0);
	.menu {
	  .w(0);
	  .dNone;
	  ul {
		> li {
		  > a {
			.pl(15px);
			.pr(15px);
			> i {
			  .mr(@menuPaddingLR);
			}
		  }
		}
	  }
	}
	&.mobi-open {
	  .w(80%);
	  .menu {
		.dBlock;
		.w(100%);
	  }
	}


  }
  .top-bar {
	.dNone;
  }
  main {
	.pt(@topBarHeight);
  }
  .js-scrolled-h {
	overflow-x: auto;
  }
  .content {
	.p(20px 5px);
  }
}
@media @mobileSmall {
  .tbl {
	td {
	  .p(5px);
	}
	&.-mobi-xs-one-line {
	  .td {
		.w100;
		.dFlex;
		align-items: stretch;
		.td-title {
		  .w(40%);
		  .m(-10px 10px -10px -10px);
		  .dFlex;
		  align-items: center;
		}
	  }
	}
  }
  .content {
	.p(20px 5px);

	h1 {
	  .fs(1.2rem);
	}
  }
  .panel-top {
	.mt(20px);
	.btn {
	  .mb(5px);
	}
	.-dates {
	  .fs(.9rem);
	}
	.panel-top-dates-item {
	  .w(35%);
	  input {
		.fs(.9rem);
	  }
	}
  }
  .panel-top-dates-wrap {
	.-viv {
	  .dNone;
	}
	.-dates {
	  .w(75%);
	  .flexSa();
	  .panel-top-dates-item {
		.w(40%);
	  }
	}
	.btn-wrap {
		.btn {
		  .p(8px 5px);
		}
	}
  }
  .mobile-mt20 {
	.mt(20px);
  }
  .win-ftr-btns {
	.btn {
	  .m(5px) !important;
	}
  }
  .win {
	.h {
	  .win-ftr-btns {
		.w100;
		.dFlex;
		.flexWrap;
		//.flexSa;
		justify-content: flex-end;
		.mt(20px);
		.btn {
		  .m(5px) !important;

		  &:nth-child(2) {
			.dNone;
		  }
		}
	  }
	}
  }
}

@media print {

  @page {
	size: auto;
	padding: 0mm;
	margin: 0mm 0mm;
  }


  body {
	.fs(@fontSizePrint) !important;
	* {

	}
  }
  a {
	.tdNone() !important;
  }
  .no-print {
	display: none;
  }

  .btn {
	.dNone;
  }
  main {
	.p(0);
	.dBlock;
  }
  .content {
	.p(0px);
	.w100 !important;
  }
  .win {
	box-shadow: none;
	//.border(@borderColor);

	> .h {
	  .fa-close {
		.dNone;
	  }
	}
	> .f {
	  .dNone;
	}
	> .c {
	  .-wrap {
		.p(@winPadLR 0);
	  }
	}
  }

  input, select {
	border: none !important;
	.bb(@borderColor, 1px) !important;
	.baradius(0px) !important;
  }
  textarea {
	.baradius(0px) !important;
	//.border(@borderColor) !important;
	border: none !important;
	.bl(@borderColor, 2px) !important;
  }
}
@media not print {
  #root {
	.h(100%);
  }
  .print-only {
	.dNone;
  }

  .anim-show {
	animation: 200ms ease-out 0s 1 animShow;
  }
  .anim-win {
	animation: 200ms ease-out 0s 1 animShow, 200ms ease-out 0s 1 animScale;
  }
}
