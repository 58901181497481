@import 'mixins.less';
@import 'config.less';

.logs-tbl {
  .col-date {
    .w(12%);
    .tac;
  }
  .col-txt {
    .w(68%);
    &.-long {
      .fs(.8rem);
    }
	  .path {
		  .fs(.6rem);
		  .m(10px 0 0 0);
		  .p(0);
	  }
  }
  .col-ltype {
    .w(10%);
    .tac;
  }
  .col-stype {
    .w(10%);
    .tac;
  }

  .log-10 {
    .bgr(#f98782);
  }
  .log-15 {
    .bgr(#000);
    .clr(#fff);
  }

}