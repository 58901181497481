@import 'mixins.less';
@import 'config.less';
.report-tbl {
	.thead {
		.tr {
			.td {
				.flexCntr;
			}
		}
	}
	.tbody {
		.fs(0.88rem);
		.fw(400);

	}
	@wTimes: 7%;
	.td {
		.tCenter;
	}

	.col-client {
		.w(10%);
	}
	.col-id{
		.w(@wTimes);
	}
	.col-created {
		.w(5%);
	}
	.col-created {
		.w(10%);
	}
	.col-time_accept {
		.w(7%);
	}
	.col-courier {
		.w(10%);
	}
	.col-address {
		//.w(10%);
		flex: 1;
	}
	.col-price_courier {
		.w(7%)
	}
	.col-profit
	{
		.w(5%)
	}
	.col-order_cost {
		.w(8%);
	}

	.col-time_courier_arrive,
	.col-time_courier_wait,
	.col-time_delivery,
	.col-time_delivery_full
	{
		.w(@wTimes);
	}


	.tr {
		&.special {
			.td {
				.bgr(#8b00ff);
			}
		}
	}
}