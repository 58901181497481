@import 'mixins.less';
@import 'config.less';

.users-list {
	//.-info {
	//  .mt(10px);
	//  .pt(5px);
	//  .bt(@borderClr, 1px, dotted);
	//  .clr(@blueClr);
	//  .fs(90%);
	//  p {
	//	.p(0);
	//	.m(5px 0 0 0)
	//  }
	//}
	//.-name {
	//.im {
	//  .circle(30px);
	//
	//  .mr(10px);
	//  .shadow(0px, 0px, 2px, 0px, 0.5);
	//  background-position: center center;
	//  background-size: cover;
	//}
	//a {
	//  .clr(@txtClr);
	//  &:hover {
	//	.tdUnd;
	//  }
	//}
	//}
	.col-act {
		.w(5%);
	}

	.col-name {
		//.w(30%);
		flex: 1;

		.im {
			.circle(30px);

			.mr(10px);
			.shadow(0px, 0px, 2px, 0px, 0.5);
			background-position: center center;
			background-size: cover;
		}

		a {
			.clr(@txtClr);

			&:hover {
				.tdUnd;
			}
		}
	}

	.col-login {
		.w(10%);
	}

	.col-role {
		.w(15%);
	}

	.col-tel,
	.col-email,
	.col-tg {
		.w(15%);
	}

	.tr {
		&.disabled {
			.clr(#ccc) !important;
			.col-name {
				a {
					.clr(#ccc);
				}
			}
		}
	}

	@media @mobileSmall {
		&.tbl {
			.td {
				&.col-act {
					.w(10%);
				}

			}
		}
	}

}

.user-edit-avatar {
	h3 {
		.mb(10px);
	}

	.-av {
		.wMax(200px);
		.mr(20px);

		img {
			.w100;
		}
	}
}

.online-users {
	.fixed;
	right: 0;
	top: @topBarHeight;
	//.z(@zTop);
	//.bgrRGBA(.8);
	.bgr(#fff);
	.w(70%);
	.hMax(80vh);
	overflow: auto;
	//.box(50%, 20vh);
	.border(#000);
	.dNone;

	&.show {
		.dBlock;
	}


	.-wrp {
		.box(100%);
		.relative;
	}

	.close {
		.absolute;
		.fs(1.2rem);
		top: 10px;
		right: 10px;

		&:hover {
			.clr(@greenClr);
			.pointer;
		}
	}

	.ou-ua {
		.fs(.7rem);
	}

	.ou-url {
		.fs(.7rem);
	}
}

