//////////////////////////////////////////
////////// Глобальные функции ////////////
////////// ver 2020.02.02     ////////////
//////////////////////////////////////////
.zeromp() {
  margin: 0;
  padding: 0;
}
.zero() {
  margin: 0;
  padding: 0;
  list-style: none;
}

.clr(@clr: @gColor){
  color: @clr;
}
.vtop() { vertical-align: top;}
.vmiddle() {vertical-align: middle;}

.flleft() { float: left}
.flright() { float: right}



.strike() {
  text-decoration: line-through;
}
.bold() {
  font-weight: bold;
}
.fSize(@size){
  font-size: @size;
}
.fs(@size) {
  .fSize(@size);
}
.fWeight(@weight: 500){
  font-weight: @weight;
}
.fw(@weight: 500){
  .fWeight(@weight);
}
.fFamily(@font){
  font-family: @font;
}
.ff(@font){
  .fFamily(@font);
}
.italic() {
  font-style: italic;
}
.normal() {
  font-style: normal;
}

.relative() {
  position: relative;
}
.absolute(){
  position: absolute;
}
.z(@index) {
  z-index: @index;
}
.absoluteLT(@left: 0px, @top: 0px){
  position: absolute;
  left: @left;
  top: @top;
}
.fixed(){
  position: fixed;
}
.dBlock(){
  display: block;
}
.dNone(){
  display: none;
}
.dInline(){
  display: inline-block;
}
.dTable() {
  display: table;
}
.dRow() {
  display: table-row;
}
.dCell() {
  display: table-cell;
}
.dFlex(){
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.dFlexi(){
  display: inline-flex;
}
.flexWrap() {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flexSb() {
  justify-content: space-between;
  -ms-flex-pack: justify;
}
.flexSa() {
  justify-content: space-around;
}
.flexCntr() {
  justify-content: center;
  -ms-flex-pack: center;
}
.overflow() {overflow: hidden;}
///////////////////// BORDER
.border(@color: @gColor, @width: 1px, @type: solid ){
  border:@arguments;
}
.borderTop(@color: @gColor, @width: 1px, @type: solid ){
  border-top:@arguments;
}
.bt(@color: @gColor, @width: 1px, @type: solid) {
  .borderTop (@color;@width;@type);
}
.borderRight(@color: @gColor, @width: 1px, @type: solid ){
  border-right:@arguments;
}
.br(@color: @gColor, @width: 1px, @type: solid) {
  .borderRight (@color;@width;@type);
}
.borderBtm(@color: @gColor, @width: 1px, @type: solid ){
  border-bottom:@arguments;
}
.bb(@color: @gColor, @width: 1px, @type: solid) {
  .borderBtm (@color;@width;@type);
}
.borderLeft(@color: @gColor, @width: 1px, @type: solid ){
  border-left:@arguments;
}
.bl(@color: @gColor, @width: 1px, @type: solid) {
  .borderLeft (@color;@width;@type);
}

.bradius(@top: 5px, @right: 5px, @bottom: 5px, @left: 5px) {
  border-radius: @arguments;
}
.border-radius(@top: 5px, @right: 5px, @bottom: 5px, @left: 5px){
  border-radius: @arguments;
}

.baradius(@arg: 5px){
  border-radius: @arg;
}
.border-radius-all(@arg: 5px){
  border-radius: @arg;
}
.imgMax(){
  max-width: 100%;
  max-height: 100%;
}
.imgCentered(){
  .absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  .imgMax;
}
.pointer() {
  cursor: pointer;
}
.curdef() {
  cursor: default;
}
.tac() { text-align: center; }
.upcase() {  text-transform: uppercase;}
.clear() { clear: both}
.inline() { display: inline-block;}

.cntr() {
  margin-left: auto;
  margin-right: auto;

}
/////////////////////// Текстовые
.tdNone() {  text-decoration: none;}
.tdUnd() {  text-decoration: underline;}
.bold() {  font-weight: bold;}
.tLeft() {text-align: left;}
.tRight() {text-align: right;}
.tCenter() {text-align: center;}
.tJustify() {text-align: justify;}
.tUp(){text-transform: uppercase;}
.tNone(){text-transform: none;}
.lh(@size: 100%) {line-height: @size}
.noWrap() {white-space: nowrap}


.mOne( @size: 10px ){margin: @size;}
.m( @size: 10px ){.mOne(@size)}
.mAll( @top: 10px, @right: 10px, @bottom: 10px, @left: 10px ){margin: @arguments;}
.mTwo( @one: 10px, @two: 10px){margin: @arguments;}
.mTop( @mrg: 10px ){margin-top: @mrg;}
.mt(@mrg) {.mTop(@mrg)}
.mBtm( @mrg: 10px ){margin-bottom: @mrg;}
.mb(@mrg) {.mBtm(@mrg)}
.mLeft( @mrg: 10px ){margin-left: @mrg;}
.ml(@mrg) {.mLeft(@mrg)}
.mRight( @mrg: 10px ){margin-right: @mrg;}
.mr(@mrg) {.mRight(@mrg)}

.pOne( @size: 10px ){padding: @size;}
.p( @size: 10px ){padding: @size;}
.pAll( @top: 10px, @right: 10px, @bottom: 10px, @left: 10px ){  padding: @arguments;}
.pTwo( @one: 10px, @two: 10px){padding: @arguments;}
.pTop( @mrg: 10px ){padding-top: @mrg;}
.pt(@mrg) {.pTop(@mrg)}
.pBtm( @mrg: 10px ){padding-bottom: @mrg;}
.pb(@mrg) {.pBtm(@mrg)}
.pLeft( @mrg: 10px ){padding-left: @mrg;}
.pl(@mrg) {.pLeft(@mrg)}
.pRight( @mrg: 10px ){padding-right: @mrg;}
.pr(@mrg) {.pRight(@mrg)}

.box(@width, @height: @width){
  width: @width;
  height: @height;
}
.box2d(@size){
  width: @size;
  height: @size;
}
.box100(){
  width: 100%;
  height: 100%;
}
.w100() {
  width: 100%;
}
.w(@size){
  width: @size;
}
.wMin(@size){
  min-width: @size;
}
.wMax(@size){
  max-width: @size;
}
.h100() {
  height: 100%;
}
.h(@size){
  height: @size;
}
.hMin(@size){
  min-height: @size;
}
.hMax(@size){
  max-height: @size;
}
.circle(@size) {
  width: @size;
  height: @size;
  border-radius: 50%;
}

.bgr(@color: #ccc){
  background: @color;
}
.bgrClr(@color: #ccc){
  background-color: @color;
}
.bgrSize(@s: cover) {
  background-size: @s;
}
.bgrRGBA(@a:0.8, @r:0, @g:0, @b:0){
  background: rgba(@r, @g, @b, @a);
}
.bgrUrl(@url){
  background-image: url(@url);
}
.bgrUrlPrm(@url: "", @info: ""){
  background: url(@url) @info;
}
.bgrPos(@left:0, @top:0){
  background-position: @left @top;
}
.bgrGradient(@c1: #000, @c2: #fff){
  background: @c1 linear-gradient(to bottom, @c1 0%, @c2 100%);
}

.shadow(@x: 0px; @y: 0px; @blur: 1px; @spread: 0px; @opacity: 1) {
  box-shadow: @x @y @blur @spread rgba(0, 0, 0, @opacity);
}
.tshadow(@x: 0px; @y: 0px; @spread: 0px; @opacity: 1) {
  text-shadow: @x @y @spread rgba(0, 0, 0, @opacity);
}
///////////////// TRANSFORM
.rotate(@deg){
  transform: rotate(@deg);
  -webkit-transform: rotate(@deg);
}
.zoom(@rate) {
  transform: scale(@rate, @rate);
}
.absoluteTopCenter() {
  top: 50%;
  transform: translateY(-50%);
}
.absoluteLeftCenter() {
  left: 50%;
  transform: translateX(-50%);
}
.absoluteCenter() {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
// Encoded SVG Background
.encoded-svg-background(@svg) {
  @url: `encodeURIComponent(@{svg})`;
  background-image: url("data:image/svg+xml;charset=utf-8,@{url}");
}

.triangleUp(@s, @color: #000) {
  width: 0;
  height: 0;
  border-left: @s solid transparent;
  border-right: @s solid transparent;
  border-bottom: @s solid @color;
}
.triangleRight(@s, @color: #000) {
  width: 0;
  height: 0;
  border-top: @s solid transparent;
  border-bottom: @s solid transparent;
  border-left: @s solid @color;
}
.triangleDown(@s, @color: #000) {
  width: 0;
  height: 0;
  border-left: @s solid transparent;
  border-right: @s solid transparent;
  border-top: @s solid @color;
}
.triangleLeft(@s, @color: #000) {
  width: 0;
  height: 0;
  border-top: @s solid transparent;
  border-bottom: @s solid transparent;
  border-right:@s solid @color;
}
.grayscale(@p: 100%) {
  -webkit-filter: grayscale(@p); /* Safari 6.0 - 9.0 */
  filter: grayscale(@p);
}