@import 'mixins.less';
@import 'config.less';

@filedErrorZindex: 10;
@smartSelectPopupZindex: @filedErrorZindex + 10;

input[type=text], input[type=tel], input[type=password], input[type=email], input[type=date],
textarea, select, .input {
	display: block;
	width: 100%;
	height: calc(2.25rem + 2px);
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	.fFamily(inherit);
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid @inputBorderClr;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

	&:focus {
		border-color: @inputBorderClrHover;
		outline: 0;
	}

	@media print {
		.fs(@fontSizePrint);
	}

}
.fi-mb {
	.mb(@itemsMarginVertical);
}
.form-item {
	.mb(@itemsMarginVertical);

	> label {
		.dBlock;
		.clr(#000);
		//.clr(@blueClr);

		&:not(.check) {
			.mb(5px);
			//.tUp;
			.fw(700);
		}

		span {
			.fs(0.8rem);
			.italic;
			.fw(300);
			.clr(@txtLightClr);
			text-transform: lowercase;
		}
	}
	&.-lbl-normal {
		> label {
			.fw(500);
		}
	}
	//.label {
	//.fw(700);
	//}
	.descr {
		.fs(0.8rem);
		.fw(300);
		.italic;
		.clr(@txtLightClr);
		.predefined {
			.mt(5px);
			> * {
				.dInline;
				//.clr(#fff);
				.bgr(@inputBorderClr);
				//.bgr(#7e7ec7);
				.m(0 5px 5px 0);
				.p(3px 10px);
				.pointer;
				//text-decoration: underline dotted;
				font-style: normal;
				.baradius(3px);
				.fs(.7rem);

				&:hover {
					.bgr(@greyBgr);
					.clr(#fff);
				}
			}
		}
	}
	&.disabled {

		p {
			padding: .375rem .75rem;
			border-radius: .25rem;
			.bgr(@borderClrDisabled);
			em {
				//.fw(100);
				.clr(darken(#f1f1f1, 20%));
			}
		}
		label {
			//.clr(@greenClr);
			//.clr(@txtLightClr);
		}
		input, textarea, p, select {
			.border(@borderClrDisabled);


			&:focus {
				border-color: #f1f1f1;
			}
		}

		.fi-field {
			.-ico, .-icor {
				.border(@borderClrDisabled);
				//.border(@borderClr);
				.clr(#f1f1f1);
			}
		}
	}

	&.show-only {
		label {
			.clr(@greenClr);
		}
		input, textarea, p, select {
			.clr(#000);
		}
	}
}

@icoWidth: 40px;
.fi-field {
	.relative;
	display: block;
	width: 100%;
	min-height: calc(2.25rem + 2px);


	.fFamily(inherit);
	//color: #495057;
	.clr(lighten(@txtClr, 10%));
	.fw(300);
	//background-color: #fff;

	input[type=text], input[type=email], input[type=password], textarea {
		.box(100%);
		min-height: calc(2.25rem + 2px);
	}

	&.withIco {
		> div:last-child:not(.-icor) {
			.pl(calc(@icoWidth + .7rem));

		}

		> input, > textarea, > select, > p {
			.pl(calc(@icoWidth + .7rem));
		}

		.smart-select {
			.pl(calc(@icoWidth - 2px)) !important;
		}

	}
	&.withIcoRight {

	}
	.-ico {
		.pl(0);
		.absolute;
		top: 0px;
		left: 0px;
		.z(1);
		//.box(@icoWidth, calc(100% - 4px));
		.box(@icoWidth, 100%);
		.bgr(#fff);
		.border(@borderClr);
		//.border(transparent);
		//.br(@borderClr);
		border-radius: .25rem 0 0 .25rem;
		.dFlex;
		.flexCntr;
		align-items: center;
		.fs(1.3rem);
		.clr(@borderClr);
		//.clr(#fff);
	}
	//иконка справа
	.-icor {
		.pl(0);
		.absolute;
		top: 0px;
		right: 0px;
		.box(@icoWidth, 100%);
		.bgr(@borderClr);
		.clr(#fff);
		//.border(@borderClr);
		.dFlex;
		.flexCntr;
		align-items: center;
		.fs(1.3rem);
		border-radius: 0 .25rem .25rem 0;
		&:hover {
			.bgr(darken(@borderClr, 10%));

		}
	}
	.popup-msg {
		@arrowSize: 10px;
		//.dNone;
		.absolute;
		left: 0;
		top: calc(100% + @arrowSize/2);
		.z(@filedErrorZindex);


		//&.active {
		//  .dBlock;
		//}


		> div {
			//.bgr(@inputBorderClr);
			.bgr(@redClr);
			.clr(#fff);
			.baradius(5px);
			//.p(5px 10px);
			padding: .375rem .75rem;
			.relative;
			&:after {
				content: "";
				.triangleUp(@arrowSize, @redClr);
				.absolute;
				left: 10%;
				top: (@arrowSize - 1px) * -1;
				.z(20);
			}
		}
	}

	@media print {
		.-ico {
			.dNone;
		}
		&.withIco {
			> * {
				.pl(.75rem) !important;
			}
		}
	}

}
.fi-field-radio {
	.dFlex;
	label {
		.dFlex;
	}
	&.inline {
		.dFlexi;
		.m(0 20px 20px 0);
		width: auto;
		input {
			.mr(5px);
		}
	}
	.radio-ico {
		.m(0 5px);
	}
}
.form-files-list {
	//@maxWidth: 150px;

	.p(0);
	.m(20px 0 0 0);
	.dFlex;

	li {
		.dFlexi;
		.flexSb;
		align-items: center;
		.m(0 40px 20px 0);
		//.wMax(@maxWidth);
		a {
			&:hover {
				.tdUnd;
			}
		}
		.fico {
			.vmiddle;
			.mr(5px);
		}
		i {
			.ml(1rem);
			.clr(@txtLightClr);
			&:hover {
				.pointer;
				.clr(@txtClr);
			}

			&.fa-warning {
				.clr(@redClr);
			}
		}

		figure {
			.m(0);
			.tac;

			img {
				.wMax(100%);
				.h(60px);
			}

			figcaption {
				//.tac;
				.fs(0.8rem);
			}
			i {
				.clr(@txtClr);
				.fs(3rem);
			}
		}
	}
}
.form-chbox-inline {
	.dInline;
	.m(0 10px 10px 0);
}
.smart-select {

	@fsMargins: 3px;
	@br: .25rem;

	.relative;

	.label {
		.bold;
		.mb(5px);
	}

	.ss-selected {
		.zero();
		.w100;
		//min-height: 2.25rem;
		min-height: calc(2.25rem + 2px);
		.bgr(#fff);
		border: 1px solid @inputBorderClr;
		border-radius: @br;
		transition: border-color .15s ease-in-out;
		.relative;
		.pr(@fsMargins * 4);
		.dFlex;
		align-items: center;
		.flexWrap;

		//&:after {
		//	content: "\f0d7";
		//	.fs(1rem);
		//	.clr(@inputBorderClr);
		//	.absolute;
		//	.absoluteTopCenter;
		//	right: @fsMargins * 2;
		//	.ff('FontAwesome');
		//}
		li {
			.dFlexi;
			.flexSb;
			align-items: center;
			.m(@fsMargins);
			.p(3px 5px);
			.bgr(@blueClr);
			.clr(#fff);
			.baradius(5px);

			i {
				.ml(5px);
				.clr(lighten(@blueClr, 20%));
				&:hover {
					.clr(#fff);
				}
			}


		}
		//placeholder
		span {
			.dInline;
			.m(@fsMargins);
			.p(3px 5px);
			.italic;
			.clr(@inputBorderClr);
			.bgr(transparent);
		}
	}
	&:hover {
		.fs-selected {
			border-color: @inputBorderClrHover;
		}
	}
	.ss-pop {
		.dNone;
		.absolute;
		left: 0;
		top: 97%;//cal(100% - @br/2);
		.z(@smartSelectPopupZindex);
		.border(@inputBorderClrHover);
		.bgr(#fff);
		.w(100%);

		.bradius(0, 0, @br, @br);
		.overflow;

		&.show {
			.dBlock;
		}
	}
	.ss-srch {
		//.bgr(@borderClr);
		.p(@fsMargins * 2);
		input {
			.border(@inputBorderClrHover);
		}
	}
	//.scroll-wrap {
	//.h(30vh);
	//.hMax(42vh);
	//}
	.ss-list {
		.zero;
		//.h(50vh);
		//.h100;
		//overflow-y: auto;
		.hMax(82vh);

		li {
			.p(@fsMargins * 2);
			.dFlex;
			align-items: center;

			.im {
				background-color: @topBarSrchBgr;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				.baradius(50%);
				.box(40px);
				.overflow;
				//.dFlex;
				//align-items: center;
				//.flexCntr;
				.mr(10px);
				//img {
				//  .wMax(100%);
				//  .hMax(100%);
				//}
			}
			//img {
			//.bgr(@topBarSrchBgr);
			//.baradius(50%);
			//.w(40px);
			//.h(auto);
			//.mr(10px);
			//}

			&:hover {
				.bgr(@blueClr);
				.clr(#fff);
				.pointer;
			}
			&.selected {
				.bgr(#f1f1f1);
				&:hover {
					.clr(@txtClr);
				}
			}
			&.isGroup {
				.flexCntr;
				.bold;
				.bb(@inputBorderClrHover);
			}
		}
	}
	&.-one {
		.ss-selected {
			li {

				.m(@fsMargins);
				.p(3px 5px);
				//.italic;
				.clr(@txtClr);
				.bgr(transparent);
			}
		}
	}
	&.-arrowed {
		.ss-selected {
			&:after {
				content: "\f0d7";
				.fs(1rem);
				.clr(@inputBorderClr);
				.absolute;
				.absoluteTopCenter;
				right: @fsMargins * 2;
				.ff('FontAwesome');
			}
		}

		&:hover {
			.ss-selected {
				&:after {
					.clr(@txtClr);
				}
			}
		}
	}
	&.disabled {
		.ss-selected {
			border-color: @borderClrDisabled;
		}
	}
}
