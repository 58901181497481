@import 'mixins.less';
@import 'config.less';

.start-day {
	> .win {
		//.w(90vw);
		@media @desktop {
			//.w(80vw);
		}
	}
}
.start-day-admin {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 10px 20px;

	h3 {
		.mb(1rem);
	}
	.form-item {
		//.mb(0.5rem);
		.m(0);
		.fi-field {
			.hMin(20px);
		}

		&.disabled {
			label {
				.clr(@txtLightClr);
			}
		}
	}

	@media @desktop {
		grid-template-columns: repeat(2, 1fr);
	}
}


